/**
 * If anything is wrong with mui ssr. Please take a look at official demo first:
 * https://github.com/mui-org/material-ui/blob/master/examples/nextjs/pages/_app.js
 *
 * @TODO refactor to share ssr logic for mui and styled-components when next time someone want to copy this
 *
 */

import type { FC } from 'react';
import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';

const { AsyncLoadDatadogRumScript } = await import('@jerry-serverless/ui-analytics/datadog/datadog-rum-async');

const { ErrorBoundary } = await import(/* webpackChunkName: "_app_ErrorBoundary" */ '@jerry-serverless/next-sentry');

const { CommonMetaDownloadApp } = await import(
  /* webpackChunkName: "_app_CommonMetaDownloadApp" */ '@jerry-serverless/seo-apps-common'
);

const { PreviewModeContext } = await import(
  /* webpackChunkName: "_app_PreviewModeContext" */ '@jerry-serverless/seo-utils/lib/IfPreviewModeOn'
);

const App: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <ErrorBoundary>
      <Head>
        <meta charSet="utf-8" key="charSet" />
        <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
      </Head>
      <AsyncLoadDatadogRumScript />
      <CommonMetaDownloadApp />
      <PreviewModeContext.Provider value={pageProps.preview}>
        <Component {...pageProps} />
      </PreviewModeContext.Provider>
    </ErrorBoundary>
  );
};

export default App;
